import React from "react";
import mainPageModule from "./MainPage.module.css"
import febrary2025 from "../../../img/show/past/exhibition_winter_2025.jpg";
import april2025 from "../../../img/show/past/april_2025.jpg";
import may2025 from "../../../img/show/past/may_2025.jpg";
import june2025 from "../../../img/show/past/june_12_2025.jpg";
import exhibition_june2025 from "../../../img/show/past/exhibition_june_22_2025.jpg";
import jule2025 from "../../../img/show/past/jule_17_2025.jpg";
import august2025 from "../../../img/show/past/August_2025.jpg";
import september2025 from "../../../img/show/past/september_2025.jpg";
import october2025 from "../../../img/show/past/october_2025.jpg";
import november2025 from "../../../img/show/past/november_2025.jpg";
import december2025 from "../../../img/show/past/December_2025.jpg";
const MainPage = (props)=>{
    return(
        <div>
            <div className={mainPageModule.div_img}>

                <img className={mainPageModule.img} src={febrary2025}/>
                <br/><br/><br/><br/>
                <img className={mainPageModule.img} src={april2025}/>
                <br/><br/><br/><br/>
                <img className={mainPageModule.img} src={may2025}/>
                <br/><br/><br/><br/>
                <img className={mainPageModule.img} src={june2025}/>
                <br/><br/><br/><br/>
                <img className={mainPageModule.img} src={exhibition_june2025}/>
                <br/><br/><br/><br/>
                <img className={mainPageModule.img} src={jule2025}/>
                <br/><br/><br/><br/>
                <img className={mainPageModule.img} src={august2025}/>
                <br/><br/><br/><br/>
                <img className={mainPageModule.div_img_september} src={september2025}/>
                <br/><br/>
                <img className={mainPageModule.img} src={october2025}/>
                <br/><br/><br/><br/>
                <br/><br/>
                <img className={mainPageModule.img} src={november2025}/>
                <br/><br/><br/><br/>
                <br/><br/>
                <img className={mainPageModule.img} src={december2025}/>
                <br/><br/><br/><br/>

            </div>

        </div>

    )
}
export default MainPage;
